.type13 {
  .title {
    color: #fff;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    min-height: 1rem;
  }

  .ul {
    .item {
      a {
        color: #fff;
        vertical-align: -0.7em;
        font-size: 16px;
        transition: all 0.25s ease;
        white-space: nowrap;
        padding: 12px 0;
        // display: inline-block;
        &:hover{
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
}
